





















import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class MQRobot extends Vue {}
