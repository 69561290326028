
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ProductFeatures extends Vue {

  @Prop() private sectionTitle!: string;
}
