































































import { Component, Prop, Vue } from 'vue-property-decorator';
import HeroFeatures from '@/components/HeroFeatures.vue';
import HeroIntro from '@/components/HeroIntro.vue';
import ProductFeatures from '@/components/ProductFeatures.vue';
import SupportWW from '@/components/SupportWW.vue';
import MQQueue from '@/inline-svg/MQQueue.vue';
import MQRobot from '@/inline-svg/MQRobot.vue';
import MQBar from '@/inline-svg/MQBar.vue';
import EventsList from '@/components/EventsList.vue';

@Component({
  components: {
    HeroFeatures,
    HeroIntro,
    ProductFeatures,
    SupportWW,
    MQQueue,
    MQRobot,
    MQBar,
    EventsList,
  },
})
export default class ServiceMultiQueue extends Vue {
  private primary = {
    buttonText: `Get Started`,
    type: 'anchor',
    to: 'https://warp.world/v1/multi-queue',
  };
}
